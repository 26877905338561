import lottie from "lottie-web";

export const topPageLottie = function () {
  const hero = document.getElementById("hero_lottie");
  if (!hero) {
    return;
  }

  const json_path =
    window.location.host == "freemethod.co.jp"
      ? "/wp/wp-content/themes/re-2022/src/json/lottie_top.json"
      : "/wp-content/themes/re-2022/src/json/lottie_top.json";

  const animate = lottie.loadAnimation(<any>{
    container: hero, // アニメーションを追加する要素
    renderer: "svg", // レンダリングのタイプ
    loop: true, // アニメーションをループさせるかどうか
    autoplay: true, // アニメーションを自動再生するかどうか
    path: json_path, // アニメーションのjsonファイルのパス
  });
  animate.setSpeed(0.5);
};
