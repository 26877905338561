const options = {
  root: null,
  rootMargin: "-1px",
  threshold: 0,
};

const header = document.querySelector("header");
const footerIntersectingCallback = function (entries: any) {
  if (!header) {
    return;
  }
  if (entries[0].isIntersecting) {
    header.classList.add("state-footerIntersecting");
    // console.log(entries[0].isIntersecting);
  } else {
    header.classList.remove("state-footerIntersecting");
    // console.log(entries[0].isIntersecting);
  }
};
const footer = document.querySelector("footer .footer_logo");

const observer = new IntersectionObserver(footerIntersectingCallback, options);

export const footerIntersecting = function () {
  if (footer) {
    observer.observe(footer);
  }
};
