import Swiper, { Scrollbar, Lazy, Autoplay } from "swiper";
import "swiper/css";

import { partsWorksInsertIcon } from "./partsWorksInsertIcon";

const options3 = {
  root: null,
  rootMargin: "-1px",
  threshold: 0,
};

// トップの遅延読み込み
const target = ".js-swiper_works";
const interSectionSliderTarget = document.querySelector(target);

const swiperWorksIntersectionCallback = function (entries: any) {
  if (entries[0].isIntersecting) {
    if (!interSectionSliderTarget) {
      return;
    }

    observer3.disconnect();

    const swiper_lazys = document.querySelectorAll<any>(".swiper-lazy");

    // lazyload
    swiper_lazys.forEach(function (elm) {
      elm.src = elm.dataset.src;
    });

    if (interSectionSliderTarget.classList.contains("initialized")) {
      return;
    }
    interSectionSliderTarget.classList.add("initialized");
    new Swiper(target, {
      modules: [Scrollbar, Lazy, Autoplay],
      loop: true,
      slidesPerView: "auto",
      spaceBetween: 12,
      followFinger: true,
      lazy: false,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      scrollbar: {
        el: ".swiper-scrollbar",
      },
      on: {
        init: function () {
          // console.log("swiper initialized");
          // loop：trueの場合cloneされたitemにhover用iconを挿入したい
          partsWorksInsertIcon();
        },
      },
    });
  }
};

const observer3 = new IntersectionObserver(
  swiperWorksIntersectionCallback,
  options3
);

export const swiperWorks = function () {
  if (interSectionSliderTarget) {
    observer3.observe(interSectionSliderTarget);
  }
};
