const options2 = {
  root: null,
  rootMargin: "-1px",
  threshold: 0,
};

// トップの遅延読み込み
const interSectionTarget = document.querySelector("img[data-src]");
const images = document.querySelectorAll<any>("img[data-src]");

const topPageIntersectionCallback = function (entries: any) {
  if (entries[0].isIntersecting) {
    images.forEach(function (elm) {
      if (elm.src != elm.dataset.src) {
        elm.src = elm.dataset.src;
      }
    });
  }
};

const observer2 = new IntersectionObserver(
  topPageIntersectionCallback,
  options2
);

export const topPageIntersection = function () {
  if (interSectionTarget) {
    observer2.observe(interSectionTarget);
  }
};
