// parts-worker
// hover bg
export const partsWorksInsertIcon = function () {
  const icon =
    window.location.host == "freemethod.co.jp"
      ? "/wp/wp-content/themes/re-2022/src/image/common/ico_pageview.svg"
      : "/wp-content/themes/re-2022/src/image/common/ico_pageview.svg";

  const icon_element =
    '<div class="c-works_hover"><span><img class="icon_img" src="' +
    icon +
    '" alt="icon"></span></div>';

  const target = document.querySelectorAll(".js-swiper_works .item_link");
  if (document.querySelector("body.isPc")) {
    if (target) {
      Array.from(target, (x) =>
        x.insertAdjacentHTML("beforeend", icon_element)
      );
    }
  }
};;
