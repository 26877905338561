import "./scss/style.scss";

import { userAgent } from "./js/userAgent";
import { footerIntersecting } from "./js/footerIntersecting";
import { swiperWorks } from "./js/swiperWorks";
import { swiperServiceSolution } from "./js/swiperServiceSolution";
import { selectCategory } from "./js/selectCategory";
import { topPageIntersection } from "./js/topPageIntersection";
import { topPageLottie } from "./js/topPageLottie";
import { blackListCheck } from "./js/form";
import { valueCheck } from "./js/form";

// windowに何かつっこんだ場合の型宣言です
declare global {
  interface Window {
    get_theme_file_url: String;
  }
}

userAgent();
topPageLottie();
topPageIntersection();
footerIntersecting();
swiperWorks();
swiperServiceSolution();
selectCategory();
blackListCheck();
valueCheck();

const app = document.querySelector<HTMLDivElement>("#app")!;

if (app) {
  app.innerHTML = `<h1>Hello Vite!</h1>`;
}
