type selectValueProps = 'all' | 'web' | 'app' | 'system'

// Todo：any型をやめる
const changeSelect = (event: any) => {
    if(!(event.target instanceof EventTarget)) return;
    const locationSearch = location.search;
    const selectName: 'category' = event.target.getAttribute('name');
    const selectValue: selectValueProps = event.target.value;

    // 選択したoptionに変更がなければ、遷移はしない
    const params = new URLSearchParams(locationSearch)
    const searchQueryValue = params.get('category')
    if(searchQueryValue === selectValue) return;

    // 選択したoptionにselectedを付与する
    for(const option of event.target.children){
        if(option.hasAttribute('selected')) {
            option.removeAttribute('selected')
        }
    }
    const element = <HTMLSelectElement>document.querySelector('.js--select')
    event.target.children[element.selectedIndex].setAttribute('selected', '');

    // allを選択した場合、クエリパラメータを省いて遷移する。
    // それ以外の場合、クエリパラメータを付与して遷移する。
    const regexPage = /page\/[0-9]*\//;
    const regexValue = /(web|app|system)/g;
    const newPathname = location.pathname.replace(regexPage,'');
    const isCategory = regexValue.test(selectValue)

    if(isCategory){
        const newSearchQuery = `?${selectName}=${selectValue}`;
        location.href = newPathname + newSearchQuery;
    } else if(selectValue === 'all') {
        location.href = newPathname;
    }
}

export const selectCategory = () => {
    window.addEventListener('DOMContentLoaded', () => {
        if(!document.querySelector('.js--select')) return;
        const element = <HTMLSelectElement>document.querySelector('.js--select')
        element.addEventListener('change',changeSelect)
    })
}