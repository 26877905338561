// 詐欺っぽいメールを弾くための関数
export const blackListCheck = () => {
    const companyNameInput: HTMLInputElement = document.querySelector<any>('.wpcf7-form input[name="company-name"]')
    companyNameInput.addEventListener('change', () => {
        const companyNameText: string = companyNameInput.value
        // 会社名で除外したい文字列を下記に追加していく
        const isBlackListCheck: boolean = companyNameText.includes('集客の窓口 運営事務局')

        if(isBlackListCheck) {
            const submit: HTMLButtonElement = document.querySelector<any>('input[type="submit"]')
            if(submit) submit.disabled = true
        }
    })
}

// 必須項目が全て埋まったらボタンに色がつく
export const valueCheck = () => {
    const InputContactType = document.querySelector<any>('.wpcf7-form select[name="contact-type"]')
    const InputName = document.querySelector<any>('.wpcf7-form input[name="your-name"]')
    const InputEmail = document.querySelector<any>('.wpcf7-form input[name="your-email"]')
    const InputBudget = document.querySelector<any>('.wpcf7-form select[name="budget"]')
    const InputCheckbox = document.querySelector<any>('.wpcf7-form input[type="checkbox"]')

    const arrs = [InputContactType, InputName, InputEmail, InputBudget, InputCheckbox]
    for(let i = 0; i < arrs.length; i ++) {
        arrs[i].addEventListener('change', () => {
            const InputContactType: string = document.querySelector<any>('.wpcf7-form select[name="contact-type"]').value
            const InputName: string = document.querySelector<any>('.wpcf7-form input[name="your-name"]').value
            const InputEmail: string = document.querySelector<any>('.wpcf7-form input[name="your-email"]').value
            const InputBudget: string = document.querySelector<any>('.wpcf7-form select[name="budget"]').value
            const InputCheckbox: string = document.querySelector<any>('.wpcf7-form input[type="checkbox"]').checked
            const submitBtnWrap = document.querySelector<any>('.wpcf7-form .submit-wrap')
            if(!(InputContactType === '' || InputName === '' || InputEmail === '' || InputBudget === '' || !InputCheckbox)) {
                submitBtnWrap.classList.remove('invalid')
            } else {
                submitBtnWrap.classList.add('invalid')
            }
        })
    }
}