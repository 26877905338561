import UAParser from "ua-parser-js";

export const userAgent = function () {
  const parser = UAParser();
  const target = document.querySelector("body");
  if (target) {
    if (parser.device.model) {
      target.classList.add("isSmp");
    } else {
      target.classList.add("isPc");
    }
  }
};
