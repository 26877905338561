import Swiper, { Lazy, Autoplay } from "swiper";
import "swiper/css";

export const swiperServiceSolution = function () {
  const target = ".js-swiper_serviceSolution";
  const slider_target = document.querySelector(target);

  if (!slider_target) {
    return;
  }
 new Swiper(target, {
   modules: [Lazy, Autoplay],
   centeredSlides: true,
   // slidesPerView: 5,
   loop: true,
   slidesPerView: "auto",
   spaceBetween: 12,
   preloadImages: false,
   lazy: true,
   autoplay: {
     delay: 4000,
     disableOnInteraction: false,
   },
   on: {
     init: function () {
       //  console.log("swiper initialized");
     },
   },
 });
};
